/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {faAt, faPhoneSquareAlt} from "@fortawesome/free-solid-svg-icons";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    h2: "h2",
    p: "p",
    a: "a",
    h3: "h3",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components), {FontAwesomeIcon} = _components;
  if (!FontAwesomeIcon) _missingMdxReference("FontAwesomeIcon", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, null, "Burton in Bloom"), "\n", React.createElement("div", {
    className: "w-full md:w-2/5 md:pl-6 mb-4 md:mb-0 text-right p-4 z-40 md:ml-6 bg-gray-700 rounded-r-lg md:rounded-r-none rounded-l-lg text-white inverse float-right inline-block inset-0"
  }, React.createElement(_components.h2, null, "Can you help?"), React.createElement(_components.p, null, "We desperately need some volunteers to help with the heavy work, putting up small marquees, bunting, moving chairs, etc. during our annual events."), React.createElement(_components.p, null, "If you are able to lend a hand, please email us at ", React.createElement(_components.a, {
    href: "mailto:rlmarshall7@googlemail.com"
  }, React.createElement(FontAwesomeIcon, {
    icon: faAt,
    size: "sm"
  }), " : rlmarshall7@googlemail.com"), " or telephone on ", React.createElement(_components.a, {
    href: "tel:+447773442229"
  }, React.createElement(FontAwesomeIcon, {
    icon: faPhoneSquareAlt,
    size: "sm"
  }), " : 07773 442229"), ".")), "\n", React.createElement(_components.h3, null, "How it all began"), "\n", React.createElement(_components.p, null, "In 1995 a meeting was called to ask if anyone in the village would like to be involved in organising open gardens.\nIn June 1996 gardens were opened, teas were sold and exhibitions held in the Church.  The day finished with an open air songs of praise.\nThis was the beginning of Burton in Bloom.  By 1998/9 a constitution had been written and the events were lasting for the full weekend."), "\n", React.createElement(_components.h3, null, "The Aims and Objectives of Burton in Bloom"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "The involvement of ", React.createElement("span", {
    className: "font-black"
  }, "Burton upon Stather"), " village groups and individuals in activities to develop a sense of community"), "\n", React.createElement(_components.li, null, "The choice of a village organisation(s) to annually receive the results of fund raising events"), "\n", React.createElement(_components.li, null, "The use of funds to improve the appearance of the village"), "\n", React.createElement(_components.li, null, "To encourage as many village groups and individuals as possible to work together"), "\n", React.createElement(_components.li, null, "To provide an open village day/weekend to enable village organisations to raise money – this will be organised by Burton in Bloom, who will fund all the necessary expenses associated with such an event"), "\n", React.createElement(_components.li, null, "To undertake fund raising events on behalf of Burton in Bloom to achieve the aims of the organisation"), "\n", React.createElement(_components.li, null, "To plant hanging baskets annually for the conservation area of the village"), "\n", React.createElement(_components.li, null, "To look after and replant the stone planters and herb planter provided by Burton in Bloom"), "\n", React.createElement(_components.li, null, "To plant trees, bulbs and plants to improve the appearance of the village"), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
